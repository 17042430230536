import { useSessionStorage } from '@vueuse/core'

export const useFaceWidth = () => {
  const faceWidth = useSessionStorage<number | undefined>(
    'faceWidth',
    undefined,
  )

  const setFaceWidth = (width: number) => {
    faceWidth.value = width
  }

  const removeFaceWidth = () => {
    faceWidth.value = undefined
  }

  return {
    setFaceWidth,
    faceWidth,
    removeFaceWidth,
  }
}
