<template>
  <ClientOnly>
    <div
      v-editable="blok"
      :class="[
        'mt-6 flex flex-col gap-4 rounded-lg p-6',
        backgroundClass,
        containerLayoutClass,
        ...marginClasses,
        containerClasses,
      ]"
    >
      <Headline tag="h4" look="h4" class="gap-4">
        <LazyIconFielmannGlassesWidth
          v-if="componentState === 'default'"
          class="size-6"
        />
        {{ headline }}
        <component :is="activeIcon" v-if="activeIcon" class="size-6" />
      </Headline>
      <p v-if="componentState === 'default'">
        {{ $t('faceScan.snippet_copy') }}
      </p>
      <PdTool
        :btn-id="buttonId"
        :class="isResponsive ? 'sm:ml-auto' : ''"
        :btn-class="componentState !== 'default' ? '!px-0 font-normal' : ''"
        :btn-type="buttonType"
        :btn-icon="buttonIcon"
        btn-is-small
        :btn-label="buttonLabel"
        @face-width:change="onFaceWidthChange"
        @pd:change="onPdChange"
      />
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import type { Variant } from '@scayle/storefront-nuxt'
import type { SbFaceScan } from '../types/storyblok'
const $i18n = useI18n()
const { getPrescriptionPropSetters } = useRxPrescription()
const { setLeft, setRight } = getPrescriptionPropSetters('pd')
const { faceWidth, setFaceWidth } = useFaceWidth()
const { trackNotification } = await useTrackingEvents()

const props = defineProps({
  blok: {
    type: Object as PropType<SbFaceScan>,
    default: () => {},
  },
  variant: {
    type: Object as PropType<Variant>,
    default: () => undefined,
  },
  isResponsive: {
    type: Boolean,
    default: false,
  },
})

const isResponsive = toRef(props, 'isResponsive')
const variant = toRef(props, 'variant')

const glassesWidth = computed(() => getGlassesWidth(variant))
const { marginClasses, containerClasses } = useStoryblokMargins(
  props.blok || {},
)

const componentState = computed(() => {
  if (!faceWidth.value || faceWidth.value === 0) {
    return 'default'
  }

  return isVariantMaybeFitting(variant, faceWidth) ? 'activeFit' : 'activeNoFit'
})

const buttonId = computed(
  () => props?.blok?.id || 'Face-Scan | Start Face-Scan',
)

const containerLayoutClass = computed(() => {
  return isResponsive.value
    ? 'sm:flex-row sm:items-center sm:px-4 sm:py-2 sm:mt-0 sm:h-14'
    : ''
})

const headline = computed(() => {
  switch (componentState.value) {
    case 'activeNoFit':
      return $i18n.t('faceScan.active_state_pdp_bad')
    case 'activeFit':
      return glassesWidth.value
        ? $i18n.t('faceScan.active_state_pdp_good')
        : $i18n.t('faceScan.active_state_plp')
    default:
      return $i18n.t('faceScan.snippet_title')
  }
})

const activeIcon = computed(() => {
  if (componentState.value === 'activeNoFit') {
    return 'LazyIconFielmannCancel'
  }
  if (componentState.value === 'activeFit') {
    return 'LazyIconFielmannCheck'
  }
  return null
})

const buttonType = computed(() => {
  return componentState.value !== 'default' ? 'link' : 'primary'
})

const buttonLabel = computed(() => {
  return componentState.value !== 'default'
    ? $i18n.t('faceScan.snippet_cta_repeat')
    : $i18n.t('faceScan.snippet_cta')
})

const buttonIcon = computed(() => {
  return componentState.value === 'default' ? 'LazyIconFielmannArrowRight' : ''
})

const backgroundClass = computed(() => {
  if (componentState.value === 'activeFit') {
    return 'bg-status-successlight'
  }
  if (componentState.value === 'activeNoFit') {
    return 'bg-status-warninglight'
  }
  return 'bg-shade-200'
})

const onFaceWidthChange = (data: number) => {
  setFaceWidth(data)
  trackNotification($i18n.t('rx.prescription.pd.toolSuccess'))
}

const onPdChange = (data: { left: number; right: number }) => {
  setLeft(data.left)
  setRight(data.right)
  trackNotification($i18n.t('rx.prescription.pd.toolSuccess'))
}
</script>
